.list-position-absolute {
  position: absolute;
  left: 0;
  bottom: -5rem;
  background-color: white;
  width: calc(100% - 10px);
  max-height: 40vh;
  z-index: 1;
  border: 1px solid rgb(200, 200, 200);
  border-bottom: none;
  box-shadow: 0 0 5px rgb(200, 200, 200);
  margin: 5px;
  border-radius: 0.3rem;
}

.list-item {
  padding: 8px 2px;
  border-bottom: 1px solid rgb(200, 200, 200);
  cursor: pointer;

  &:hover {
    background-color: rgba(200, 200, 200, 0.2);
  }
}

.list-item-header {
  padding: 8px 2px;
  border-bottom: 1px solid rgb(200, 200, 200);
}

.list-col {
  overflow: hidden;
  white-space: nowrap;
}