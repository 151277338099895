$plateBackgroundColor: rgb(245, 245, 245);
$plateBorder: 1px solid rgb(220, 220, 220);
$dayCardContentShadow: 0 0 2px rgb(150, 150, 150);
$hoverBackgroudColor: rgba(200, 200, 200, 0.2);
$headerBackgroundColor: rgba(52, 58, 64, 1);
$scrollBarWidth: 0.3rem;

#root {
  background: url("/bg.png");
  min-height: 100vh;
}

.transparent {
  background-color: transparent;
}

.form-group {
  margin-bottom: 0.5rem;
}

.card-header {
  background-color: #fff;
}

.margin-top {
  margin-top: 1rem;
}

.plate {
  background-color: $plateBackgroundColor;
  margin-top: 1rem;
  border-radius: 0.3rem;
  border: $plateBorder;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.day-card-content-shadow {
  box-shadow: $dayCardContentShadow;
}

.screen-heigh {
  min-height: 100vh;
}

.padding-all {
  padding: 1rem;
}

.side-bar {
  margin-top: 1rem;
  border-radius: 0.3rem;
}

.modal-60 {
  max-width: 60%;
}

.modal-80 {
  max-width: 80%;
}

.day-card-content {
  border-radius: 0.2rem;
  box-shadow: $dayCardContentShadow;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: $hoverBackgroudColor;
  }
}

.month-card-content {
  border-radius: 0.2rem;
  height: 8rem;
  margin: 0.2rem;
  background-color: #fff;
  box-shadow: $dayCardContentShadow;
  overflow-y: auto;

  cursor: pointer;
  &:hover {
    background-color: $hoverBackgroudColor;
  }
}

.month-margin {
  margin: 0;
}

.month-plate {
  padding: 0.2rem 0.2rem;
}

.dashboard-header {
  // background-color: $headerBackgroundColor;
  margin-top: 1rem;
}

.dashboard-header-text {
  font-size: 1rem;
  font-weight: bold;
}

.no-border {
  border: none;
}

.container {
  min-width: 100%;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.border-topless {
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: none;
}

.link {
  color: inherit;
  text-decoration: inherit;
  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}

.td-action {
  text-align: center;
  cursor: pointer;
}

.month-appointment {
  font-size: x-small;
  white-space: nowrap;
  width: 100%;
  border-left: $scrollBarWidth solid rgba(200, 0, 0, 0.5);
  border-radius: $scrollBarWidth/2;
  padding-left: $scrollBarWidth;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 2px;
}

.week-appointment {
  font-size: small;
  width: 100%;
  background-color: #fff;
  border-left: $scrollBarWidth solid rgba(200, 0, 0, 0.5);
  border-radius: $scrollBarWidth/2;
  padding-left: $scrollBarWidth;
  box-shadow: $dayCardContentShadow;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
}

*::-webkit-scrollbar {
  width: $scrollBarWidth;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: $scrollBarWidth/2;
}

.react-datepicker-wrapper {
  display: block !important;
}

@keyframes blink {
  0% {
    background-color: rgba(100, 100, 200, 0.2);
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(100, 100, 200, 0.2);
  }
}

@media only screen and (max-width: 768px) {
  .rotate-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(230deg);
  }

  .modal-60 {
    max-width: 95%;
    margin: auto;
  }

  .modal-80 {
    max-width: 95%;
    margin: auto;
  }

  .month-appointment {
    padding-left: 0;
    margin-left: 0;
  }

  .month-card-content {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
}

